@import url('https://fonts.googleapis.com/css2?family=Lato:wght@200;300;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
  .btn-secondary {
    @apply py-2 px-4 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75
  }
  .btn-disabled {
    @apply py-2 px-4 bg-gray-400 text-white font-semibold rounded-lg shadow-md
  }
}